import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/app-check';
import 'firebase/compat/analytics';
import { getMultiFactorResolver as firebaseGetMultiFactorResolver } from 'firebase/auth';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { intercomInit } from '../variableModules/intercom.module';
import { appcuesInit } from '../variableModules/appcues.module';
import { signInWithEmailAndPassword } from '../variableModules/auth.module';
import { tosCheckFunction } from '../variableModules/tosCheck.module';
import * as coreui from '@coreui/coreui-pro';
import Swal from 'sweetalert2';
import versionJSON from './version.json';
import ComponentLoader from '../variableModules/ComponentLoader';

//-- Helper Functions -----------------------------------------------------------
const isLocalhost = () =>
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1') ||
  window.location.host.includes('.local');

const initFirebaseApp = () => {
  const firebaseConfig = {
    apiKey: `${import.meta.env.VITE_apiKey}`,
    authDomain: `${import.meta.env.VITE_authDomain}`,
    projectId: `${import.meta.env.VITE_projectId}`,
    storageBucket: `${import.meta.env.VITE_storageBucket}`,
    messagingSenderId: `${import.meta.env.VITE_messagingSenderId}`,
    appId: `${import.meta.env.VITE_appId}`,
    measurementId: `${import.meta.env.VITE_measurementId}`,
  };

  console.log(firebaseConfig);
  firebase.initializeApp(firebaseConfig);
  return firebaseConfig;
};

const initAppCheck = (app, recaptchaKey) => {
  const appCheck = firebase.appCheck();
  const provider = new firebase.appCheck.ReCaptchaEnterpriseProvider(recaptchaKey);

  // For local development, activate with debug token and disable automatic token refresh.
  if (isLocalhost()) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN =
      'f5d1c1b0-d9ac-492e-989c-86a4771ca959';
    appCheck.activate(provider, false);
  } else {
    appCheck.activate(provider, true);
  }
  return appCheck;
};

const initEmulators = (functions, functionsUS, auth, db, storage) => {
  if (isLocalhost()) {
    functions.useEmulator('localhost', 5001);
    functionsUS.useEmulator('localhost', 5001);
    // auth.useEmulator('http://localhost:9099');
    db.useEmulator('localhost', 8080);
    storage.useEmulator('localhost', 9199);
  }
};

const initSentry = (sentryEnabled) => {
  const dsn = sentryEnabled
    ? "https://bf894d60308b3ec204aee45b8c837de3@o4506330372702208.ingest.us.sentry.io/4506697576087552"
    : 'https://5b746c4b18d3d62bbd42c963754fc310@o4506330372702208.ingest.us.sentry.io/4506334421516288';

  Sentry.init({
    dsn,
    release: `deiterate@${versionJSON.version}`,
    integrations: [Sentry.browserTracingIntegration, Sentry.replayIntegration],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/admin\.deiterate\.com/,
      /^https:\/\/login\.deiterate\.com/,
    ],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });
};

const setCloudFunctionURL = () => {
  const localURL = 'http://localhost:5001/dev-deiterate/australia-southeast1';
  if (isLocalhost()) return localURL;
  return import.meta.env.VITE_cloudFunctionURL;
};

//-- Main Initialization --------------------------------------------------------
let firebaseConfig = {};
let sentryEnabled = import.meta.env.MODE !== 'development';
let appCheck = null;
let recaptchaKey = import.meta.env.VITE_recaptchaKey;
let publicKey = import.meta.env.VITE_publicKey;

let app = null;
let auth = null;
let analytics = null;
let db = null;
let firebaseAuth = null;
let functions = null;
let functionsUS = null;
let storage = null;
let storageRef = null;
let FirebaseFieldPath = null;
let FirebaseFieldValue = null;
let FirebaseTimestamp = null;

try {
  // Global dependencies
  window.ComponentLoader = new ComponentLoader();
  window.coreui = coreui;
  window.Swal = Swal;

  firebaseConfig = initFirebaseApp();

  // For local development, disable Sentry if needed.
  if (import.meta.env.MODE === 'development') {
    sentryEnabled = false;
  }

  // Set up Firebase services
  app = firebase.app();
  auth = firebase.auth(app);
  db = firebase.firestore(app);
  firebaseAuth = firebase.auth;
  functions = app.functions('australia-southeast1');
  functionsUS = app.functions('us-central1');
  storage = firebase.storage(app);
  storageRef = storage.ref();
  FirebaseFieldPath = firebase.firestore.FieldPath;
  FirebaseFieldValue = firebase.firestore.FieldValue;
  FirebaseTimestamp = firebase.firestore.Timestamp;

  // Initialize analytics if not on localhost
  if (!isLocalhost()) {
    analytics = firebase.analytics(app);
  }

  // Initialize App Check
  appCheck = initAppCheck(app, recaptchaKey);

  // Set global Cloud Function URL
  window.cloudFunctionURL = setCloudFunctionURL();

  // Initialize emulators if in local environment
  initEmulators(functions, functionsUS, auth, db, storage);

  // Initialize Sentry
  initSentry(sentryEnabled);

  // Expose globals and initialize additional modules
  window.global ||= window;
  window.signInWithEmailAndPassword = signInWithEmailAndPassword;
  appcuesInit();
  intercomInit();
  tosCheckFunction();
} catch (error) {
  console.error('Error during initialization:', error);
  if (Swal) {
    Swal.fire({
      title: 'Error during initialization, please refresh the page.',
      text: error.toString(),
      icon: 'error',
      confirmButtonText: 'Okay',
    });
  }
}

//-- Banner Injection -----------------------------------------------------------
const injectBanner = () => {
  const showBanner = () => {
    const isSmallScreen = window.innerWidth <= 1137; // Adjust the width as needed
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
    const bannerShown = sessionStorage.getItem('bannerShown');
    const path = window.location.pathname;

    console.log('Banner shown:', bannerShown);

    if (!bannerShown) {
      let message = '';
      if (isSmallScreen && isFirefox && path !== '/account_management') {
        message =
          'de.iterate may not function as expected on small screens in Firefox.';
      } else if (isSmallScreen && path !== '/account_management') {
        message = 'de.iterate may not function as expected on small screens.';
      } else if (isFirefox) {
        message = 'de.iterate may not function as expected in Firefox.';
      }

      if (message) {
        Swal.fire({
          title: 'Warning',
          text: message,
          icon: 'warning',
          confirmButtonText: 'Okay',
        }).then(() => {
          sessionStorage.setItem('bannerShown', 'true');
        });
      }
    }
  };

  window.addEventListener('load', showBanner);
  window.addEventListener('resize', showBanner);
};

window.addEventListener('DOMContentLoaded', injectBanner);

//-- Authentication State Handling ----------------------------------------------
auth.onAuthStateChanged(async (user) => {
  if (user) {
    window.__DebugUser = user;
    const token = await user.getIdToken();
    const uid = user.uid;
    document.cookie = `__session=${token}--__--${uid}`;
  }
});

//-- Exports --------------------------------------------------------------------
export {
  firebaseConfig,
  app,
  auth,
  analytics,
  db,
  firebase as firebaseImport,
  functions,
  storage,
  storageRef,
  FirebaseFieldValue,
  FirebaseTimestamp,
  FirebaseFieldPath,
  firebaseAuth,
  moment,
  publicKey,
  functionsUS,
  firebaseGetMultiFactorResolver,
};