import { db, auth } from '../config/main_config';

export const appcuesInit = async () => {
    window.AppcuesSettings = { enableURLDetection: true };
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            await db.collection('users').doc(user.uid).get().then(async (doc) => {
                if (doc.exists) {
                    let companyID;
                    if (await doc.data()?.mspImpersonation === false || await doc.data()?.mspImpersonation === undefined) {
                        companyID = await doc.data().organisations[0];
                    } else {
                        companyID = await doc.data().organisations[1];
                    }
                    console.log(companyID);
                    localStorage.setItem('companyID', companyID);
                    window.companyID = companyID;
                    var w = window;
                    var d = document;
                    var l = async function() {
                        var s = d.createElement('script');
                        s.type = 'text/javascript';
                        s.src = 'https://fast.appcues.com/206592.js';
                        var x = d.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);
                        setTimeout(async () => {
                            await db.collection('customers').doc(companyID).get().then(async (companyDoc) => {
                                if (companyDoc.exists) {
                                    Appcues.group(companyID, {
                                        // COMPANY ACCOUNT DATA
                                        companyName: companyDoc.data().customerFriendlyName,
                                    })
                                    Appcues.identify(auth.currentUser.uid, {
                                        // USER SPECIFIC DATA
                                        createdAt: new Date(user.metadata.creationTime).getTime() / 1000,
                                        planTier: companyDoc.data()?.customerPlan === undefined ? "All Standards (ISO27001)" : "Privacy Act",
                                        role: doc.data()?.roles?.owner !== undefined ? "Owner" : "User",
                                        firstName: doc.data()?.firstName,
                                        lastName: doc.data()?.lastName,
                                        email: doc.data()?.email,                                    
                                        // COMPANY SPECIFIC DATA
                                        companyName: companyDoc.data()?.customerFriendlyName,
                                        privacyActUser: companyDoc.data()?.customerPlan !== undefined ? true : false,                 
                                    })
                                    if (!window.location.href.includes("overduetasks")) {
                                        Appcues.track("Page loaded", {
                                            url: window.location.href
                                        })
                                    }
                                }
                            })
                        }, 2500)
                    };
                    if (document.readyState === 'complete') {
                        l();
                    } else if (w.attachEvent) {
                        w.attachEvent('onload', l);
                    } else {
                        w.addEventListener('load', l, false);
                    }
                }
            })      
        }

    })
}